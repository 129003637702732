import React from "react"
import Layout from "../../components/layout"
import { graphql, Link } from "gatsby"
import Helmet from 'react-helmet'

export default function Post({ data }) {
  return (
    <Layout>
      <Helmet>
        <title>{data.markdownRemark.frontmatter.title}</title>
        <link rel="canonical" href={`${data.site.siteMetadata.domain}/writing/${data.markdownRemark.frontmatter.slug}`} />
        <meta name="description" content={data.markdownRemark.frontmatter.description} />
      </Helmet>
      <section className="content-body blog-post-body py-5 py-lg-6 animated fadeIn">
        <article className="row-g row-g--project">
          <div className="container-fluid">
            <section className="row pt-5 pb-3 py-lg-0">
              <div className="col-12 px-0 col-md-10 offset-md-1 col-lg-8 offset-lg-2">         
                <h1 className="pb-3">{data.markdownRemark.frontmatter.title}</h1>
                <p>{data.markdownRemark.frontmatter.date}</p>
                <div
                  className="blog-post-content"
                  dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
                />
                <div className="pt-3">
                  <p><Link to="/writing">&larr; Other posts</Link></p>
                </div>
              </div>
            </section>
          </div>
        </article>
      </section>
    </Layout>
  )
}

export const query = graphql`
query($id: String!) {
  markdownRemark(id: { eq: $id }) {
    html
    frontmatter {
      date(formatString: "MMMM DD, YYYY")
      slug
      title
      description
    }
  },
  site {
    siteMetadata {
      title
      domain
    }
  }
}
`
